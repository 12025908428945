import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import {
  LandingSection,
  LandingSectionContainer,
  LandingSectionCurvedDivider,
  LandingSectionTitle
} from '#/src/custom/components/Landing/LandingSection'

import costs1Img from './images/costs_1.jpg'
import costs2Img from './images/costs_2.jpg'
import efficiency1Img from './images/efficiency_1.jpg'
import efficiency2Img from './images/efficiency_2.jpg'
import efficiency3Img from './images/efficiency_3.jpg'
import innovation1Img from './images/innovation_1.jpg'
import innovation2Img from './images/innovation_2.jpg'
import innovation3Img from './images/innovation_3.jpg'
import revenue1Img from './images/revenue_1.jpg'
import revenue2Img from './images/revenue_2.jpg'
import revenue3Img from './images/revenue_3.jpg'
import risk1 from './images/risk_1.jpg'
import risk2 from './images/risk_2.jpg'
import { GrandImage } from 'ui'
import { AspectRatio, chakra, GridItem, SimpleGrid, Text, VStack } from '@chakra-ui/react'

const ChakraSwiper = chakra(Swiper)

const slides = [
  {
    title: 'Increase Revenue',
    items: [
      {
        img: revenue1Img,
        title: 'Drive Engagement',
        text: 'Creating meaningful connections through compelling content and strategic interactions that inspire engagement and drive action.'
      },
      {
        img: revenue2Img,
        title: 'Increase Conversion',
        text: 'Turning interest into action through tailored strategies and seamless user experiences that drive measurable results.'
      },
      {
        img: revenue3Img,
        title: 'Upgrade AoV',
        text: 'Boosting average order value with personalized offers, strategic upselling, and optimized product bundling.'
      }
    ]
  },
  {
    title: 'Improve Efficiency',
    items: [
      {
        img: efficiency1Img,
        title: 'Use AI to automate manual tasks',
        text: 'We use AI to automate product descriptions and eCommerce workflows, saving time and improving accuracy.'
      },
      {
        img: efficiency2Img,
        title: 'Free up time with AI Assistant',
        text: 'Let our AI Assistant handle repetitive tasks, giving you more time to focus on your business.'
      },
      {
        img: efficiency3Img,
        title: 'Optimize Product Data processes',
        text: 'Enhance efficiency and accuracy by streamlining your product data processes, saving time and reducing manual effort.'
      }
    ]
  },
  {
    title: 'Drive Growth and Innovation',
    items: [
      {
        img: innovation1Img,
        title: 'Drive Innovation',
        text: 'Leveraging AI to innovate processes, enhance products, and optimize pricing for maximum impact.'
      },
      {
        img: innovation2Img,
        title: 'Do what you love',
        text: 'Focus on what you’re passionate about while we take care of the administrative tasks for you.'
      },
      {
        img: innovation3Img,
        title: 'Power growth',
        text: 'Accelerate growth by bringing products to market or expanding stores into new countries more quickly.'
      }
    ]
  },
  {
    title: 'Lower Costs',
    items: [
      {
        img: costs1Img,
        title: 'Consolidate Your Workflow',
        text: 'Simplify and optimize your workflow by bringing together PIM, eCommerce, customer support, content generation, and other essential tools into one cohesive platform.'
      },
      {
        img: costs2Img,
        title: 'Use AI to Improve Processes',
        text: 'Harness the power of AI to refine and optimize processes, improve efficiency across operations, and drive substantial cost savings for your business.'
      }
    ]
  },
  {
    title: 'Decrease Risk',
    items: [
      {
        img: risk1,
        title: 'Avoid Manual Errors',
        text: 'Reduce the risk of manual errors and enhance accuracy by unifying product data and content into a single, centralized platform, ensuring seamless management and consistency across all channels.'
      },
      {
        img: risk2,
        title: 'Document Your Processes',
        text: 'Guarantee that all processes are thoroughly documented, standardized, and easily accessible to enhance efficiency, ensure compliance, and support seamless team collaboration.'
      }
    ]
  }
]

export const SectionHelp = () => {
  return (
    <LandingSection bg="#206971" color="text.light" w="full" py={0} gap={32}>
      <LandingSectionCurvedDivider curvedSide="bottom" />
      <LandingSectionContainer maxWidth="90rem" pt={12} gap={16}>
        <LandingSectionTitle>How we can help you</LandingSectionTitle>

        <ChakraSwiper
          bg="primary.200"
          h="100%"
          modules={[Pagination, Navigation, Autoplay]}
          pagination={{
            dynamicBullets: true
          }}
          loop
          autoplay={{
            delay: 10000,
            disableOnInteraction: true
          }}
          navigation
          slidesPerView={1}
          sx={{
            position: 'relative !important',
            '&': {
              '--swiper-pagination-bottom': '0'
            },
            '.swiper-button-next, .swiper-button-prev': {
              h: '100%',
              top: 0,
              mt: 0,
              width: 'auto',
              p: '2',
              color: 'text.light',
              opacity: 1,
              '&.swiper-button-disabled': {
                opacity: 1
              },
              '&:after': {
                fontSize: { base: '30px', sm: '36px', md: '44px' }
              }
            },
            '.swiper-button-next': {
              right: 0
            },
            '.swiper-button-prev': {
              left: 0
            }
          }}
          w="100%">
          {slides.map((slide) => (
            <SwiperSlide key={slide.title}>
              <LandingSectionContainer pt={6} pb={10} gap={16} px={10}>
                <VStack>
                  <LandingSectionTitle
                    _after={{
                      content: '""',
                      display: 'block',
                      h: '0.05em',
                      w: '100%',
                      bg: '#39FF50'
                    }}>
                    {slide.title}
                  </LandingSectionTitle>
                </VStack>

                <SimpleGrid
                  gap={12}
                  columns={{
                    base: 1,
                    sm: Math.min(slide.items.length, 2),
                    md: slide.items.length
                  }}>
                  {slide.items.map((item, index) => (
                    <GridItem
                      key={index}
                      display={{
                        base: index > 0 ? 'none' : 'block',
                        sm: index > 1 ? 'none' : 'block',
                        md: 'block'
                      }}>
                      <VStack alignItems="stretch" gap={8}>
                        <AspectRatio ratio={slide.items.length === 3 ? 250 / 211 : 400 / 211}>
                          <GrandImage borderTopRadius="xl" src={item.img} alt="" />
                        </AspectRatio>

                        <Text fontSize="xl" color="#8DE8E8">
                          {item.title}
                        </Text>

                        <Text fontSize="xl" textStyle="landingBody">
                          {item.text}
                        </Text>
                      </VStack>
                    </GridItem>
                  ))}
                </SimpleGrid>
              </LandingSectionContainer>
            </SwiperSlide>
          ))}
        </ChakraSwiper>
      </LandingSectionContainer>
      <LandingSectionCurvedDivider curvedSide="top" bg="#183E47" />
    </LandingSection>
  )
}
