'use client'
import { SectionIntro } from './SectionIntro'
import { SectionExpect } from './SectionExpect'
import { SectionHelp } from './SectionHelp'
import { SectionDo } from './SectionDo'
import { SectionForm } from './SectionForm'
import { SectionStories } from './SectionStories'

export const LandingPage = () => {
  return (
    <>
      <SectionIntro />
      <SectionExpect />
      <SectionHelp />
      <SectionDo />
      <SectionStories />

      <SectionForm />
    </>
  )
}
