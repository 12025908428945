import { Box, Button, Text, VStack } from '@chakra-ui/react'
import HeroImage from 'ui/common/HeroImage'
import { GrandImage } from 'ui'
import introBgImg from './images/landing_stars_bg.webp'
import cartImg from './images/landing_cart.webp'
import logoAi from './images/granditude-ai.webp'
import {
  LandingSectionContainer,
  LandingSectionCurvedDivider
} from '#/src/custom/components/Landing/LandingSection'

export const SectionIntro = () => {
  return (
    <Box
      position="relative"
      w="full"
      textStyle="landingBody"
      bg="landing.bg.primary.900"
      zIndex={1}>
      <HeroImage
        position="absolute"
        zIndex={-1}
        src={introBgImg}
        alt="Granditude stars"
        objectFit="cover"
        priority={false}
      />

      <LandingSectionContainer position="relative" zIndex={1}>
        <VStack justifyContent="space-between" py="10vh" gap="20vh">
          <GrandImage
            src={logoAi}
            alt="Granditude ai logo"
            alignSelf="end"
            position="relative"
            top="-10vh"
            priority
          />

          <GrandImage
            src={cartImg}
            alt=""
            position="absolute"
            top={0}
            left={0}
            display={{
              base: 'none',
              md: 'block'
            }}
          />

          <VStack alignSelf="start" alignItems="flex-start" gap="10">
            <Text
              as="h1"
              color="white"
              fontSize={{
                base: '3xl',
                sm: '4xl',
                md: '5xl'
              }}
              textStyle="landingSlogan">
              You drive your vision,
              <br />
              we handle the details
            </Text>

            <Button
              colorScheme="gray"
              size="lg"
              borderRadius="xl"
              bg="white"
              fontSize="xl"
              p="1.5em"
              onClick={() => {
                const form = document.getElementById('landing-form-section')
                form?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }}>
              Tell us your story
            </Button>
          </VStack>
        </VStack>
      </LandingSectionContainer>

      <LandingSectionCurvedDivider curvedSide="top" />
    </Box>
  )
}
