import { BoxProps, TextProps, Text, VStack, Box } from '@chakra-ui/react'

export const LandingSection = (props: BoxProps) => {
  return (
    <VStack
      className="LandingSection"
      alignItems="stretch"
      justifyContent="stretch"
      py={16}
      textStyle="landingHeading"
      w="full"
      {...props}
    />
  )
}

export const LandingSectionTitle = (props: TextProps) => {
  return (
    <Text
      as="h2"
      fontSize={{
        base: '3xl',
        sm: '4xl',
        md: '5xl'
      }}
      textStyle="landingHeading"
      textAlign="center"
      {...props}
    />
  )
}

export const LandingSectionContainer = (props: BoxProps) => {
  return (
    <VStack
      maxWidth="80rem"
      margin="auto"
      w="full"
      alignItems="stretch"
      justifyContent="stretch"
      px={6}
      gap="10"
      {...props}
    />
  )
}

export const LandingSectionCurvedDivider = ({
  curvedSide,
  ...props
}: BoxProps & { curvedSide?: 'top' | 'bottom' }) => {
  return (
    <Box
      bg="white"
      h="7vw"
      borderRadius={curvedSide === 'top' ? '7vw 7vw 0 0' : '0 0 7vw 7vw'}
      w="full"
      {...props}
    />
  )
}
