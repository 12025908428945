import {
  LandingSection,
  LandingSectionContainer,
  LandingSectionCurvedDivider,
  LandingSectionTitle
} from '#/src/custom/components/Landing/LandingSection'
import StoryContactForm from './StoryContactForm'
import { Box, ListItem, UnorderedList, StackDivider } from '@chakra-ui/react'

export const SectionForm = () => {
  return (
    <LandingSection
      bg="#183E47"
      pt={0}
      color="text.light"
      gap={32}
      id="landing-form-section"
      pb={32}>
      <LandingSectionCurvedDivider curvedSide="bottom" />
      <LandingSectionContainer gap={16}>
        <LandingSectionTitle>Tell us Your story</LandingSectionTitle>
        <StoryContactForm />
        <StackDivider />
        <Box fontSize={{ base: 'xl', md: '2xl' }}>
          Our team will review your data thoroughly and reach out to discuss all the details with
          you.
        </Box>
        <UnorderedList ml={0}>
          <ListItem>All shared information is intended per month.</ListItem>
          <ListItem>Fields are optional, if you feel unsure, do not fill that in.</ListItem>
        </UnorderedList>
      </LandingSectionContainer>
    </LandingSection>
  )
}
