import React, { useState } from 'react'
import { FormWrapperCustom } from 'forms'
import { FormFieldType } from 'react-formatge/dist/esm/types'
import { FormSchemaUpdatedDataState } from 'react-formatge/dist/cjs/types'
import { formValidationRgx } from 'react-formatge'
import { rgxExtension } from 'forms/react-formatge-extensions/rgxExtension'
import { CreateFavroStoryCardFields } from '#/src/custom/api/favro/createFavroStoryCard'
import { useModal, useSlideOut } from '#/src/custom/controllers'
import { FetchError, fetchJson } from 'api'
import { ModalConfirmationProps } from '#/src/custom/controllers/modals/ModalConfirmation'
import { landingFormButtonProps, landingFormProps } from '#/src/custom/components/Landing/constants'

export interface StoryContactFormType extends CreateFavroStoryCardFields {}

const StoryContactForm = () => {
  const { openModal } = useModal()
  const { closeSlideOut } = useSlideOut()
  const [errorMsg, setErrorMsg] = useState('')

  const onSubmit = async (data: Partial<StoryContactFormType>) => {
    try {
      const res: { message: string } = await fetchJson('/api/contact/send-story-contact/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })

      closeSlideOut()
      openModal<ModalConfirmationProps>({
        title: res.message,
        componentId: 'confirmation',
        size: 'md',
        extraArgs: {
          text: 'The message has been sent, we will contact you back as soon as possible.'
        }
      })
      setErrorMsg('')
    } catch (error) {
      if (error instanceof FetchError) {
        setErrorMsg(error.data.message)
      } else {
        // eslint-disable-next-line no-console -- We want to pass the error to console
        console.error('An unexpected error happened:', error)
      }

      throw error
    }
  }

  const inputFields: FormFieldType<StoryContactFormType>[] = [
    {
      name: 'name',
      componentType: 'input',
      label: 'Your name',
      initialValue: '',
      validation: { required: true }
    },
    {
      name: 'companyName',
      componentType: 'input',
      label: 'Company Name',
      initialValue: '',
      validation: { required: true }
    },
    {
      name: 'email',
      componentType: 'input',
      label: 'Email',
      initialValue: '',
      validation: {
        required: true,
        validator: {
          regEx: formValidationRgx.email,
          error: 'Invalid email.'
        }
      }
    },
    {
      name: 'phone',
      componentType: 'input',
      label: 'Your phone',
      type: 'tel',
      initialValue: '',
      validation: {
        required: true,
        validator: {
          regEx: rgxExtension.phoneGeneric,
          error: 'Invalid phone number.'
        }
      }
    },
    {
      name: 'usersAmount',
      componentType: 'input',
      type: 'number',
      label: 'Number of users',
      placeholder: 'Number of users',
      initialValue: undefined
    },
    {
      name: 'sessionsAmount',
      componentType: 'input',
      type: 'number',
      label: 'Number of sessions',
      placeholder: 'Number of sessions',
      initialValue: undefined
    },
    {
      name: 'sessionsEngagedAmount',
      componentType: 'input',
      type: 'number',
      label: 'Engaged sessions',
      placeholder: 'Engaged sessions',
      initialValue: undefined
    },
    {
      name: 'ordersAmount',
      componentType: 'input',
      type: 'number',
      label: 'Number of orders',
      placeholder: 'Number of orders',
      initialValue: undefined
    },
    {
      name: 'averageOrderValue',
      componentType: 'input',
      type: 'number',
      label: 'Average Order Value (AoV)',
      placeholder: 'Average Order Value (AoV)',
      initialValue: undefined
    }
  ]

  const buttonProps = landingFormButtonProps

  const handleOnFormSubmit = async (
    updatedData: Partial<StoryContactFormType>,
    formData: FormSchemaUpdatedDataState<StoryContactFormType>
  ) => {
    try {
      await onSubmit(updatedData)
      formData.name.value = ''
      formData.companyName.value = ''
      formData.email.value = ''
      formData.phone.value = ''
      formData.usersAmount.value = undefined
      formData.sessionsAmount.value = undefined
      formData.sessionsEngagedAmount.value = undefined
      formData.ordersAmount.value = undefined
      formData.averageOrderValue.value = undefined
    } catch (e) {}
  }

  return (
    <FormWrapperCustom
      onSubmitCb={handleOnFormSubmit}
      error={errorMsg}
      {...landingFormProps}
      {...{ inputFields, buttonProps }}
    />
  )
}

export default StoryContactForm
